/* global localStorage, */
import React, { useState } from "react";
import {
  Flex,
  Card,
  Text,
  Box,
  Button,
  Alert,
  Image,
  useColorMode,
  useToast,
  Input,
} from "@builtbypixel/plasma";
import LinkButton from "../../../components/LinkButton";
import { Redirect, useHistory } from "react-router-dom";
import { Form, Field } from "../../../components/Form";

import { generateSlugFromSite, hasAuthToken, getSite } from "../../../helpers";
import { useHttp } from "../../../hooks";
import { useFormContext } from "react-hook-form";

const SubmitButton = ({ loading }) => {
  const { submit } = useFormContext();
  return (
    <Button
      size='lg'
      isFullWidth
      variantColor='primary'
      mb={3}
      type='submit'
      isLoading={loading}
      onClick={() => submit()}
      loadingText='Logging in securely...'
    >
      Login
    </Button>
  );
};

const Login = () => {
  const now = new Date();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const history = useHistory();
  const Http = useHttp(false);
  const loginPath = process.env.REACT_APP_LOGIN_PATH;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const referrer = history.location.state
    ? history.location.state.from.pathname
    : getSite?.loginRedirect;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = (data) => {
    setLoading(true);
    Http.post(loginPath, data)
      .then((res) => {
        setLoading(false);
        localStorage.setItem(
          `${generateSlugFromSite()}-token`,
          JSON.stringify({
            token: res.data.success.token,
            expiry: now.getTime() + 230400000, // Expires in 8 hours
          })
        );
        if (!referrer) {
          window.location.href = getSite.loginRedirect;
        } else {
          window.location.href = referrer;
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        toast({
          title: `Error logging in`,
          message: err?.response?.data?.exception
            ? err.response?.data?.exception
            : err.response?.data?.errors?.message,
          status: "error",
          position: "top",
          variant: "plain",
        });
      });
  };

  return hasAuthToken() ? (
    <Redirect to={referrer || getSite.loginRedirect} />
  ) : (
    <Flex
      align='center'
      justify='center'
      w='100vw'
      h='100vh'
      direction='column'
      backgroundColor='global.bg'
    >
      <Card maxWidth={500} borderRadius='20px' py='30px'>
        <Flex px={10} align='center' justify='center' w='100%'>
          <Image src='/images/aps_logo.png' objectFit='contain' size='170px' />
        </Flex>
        <Flex align='center' direction='column'>
          <Flex
            direction='column'
            align='center'
            justify='center'
            w='100%'
            maxWidth='400px'
          >
            <Text as='h1' fontSize='26px' fontWeight='bold' color='primary'>
              Login
            </Text>
            <Box py={10} px={5} w='100%'>
              <Form onSubmit={onSubmit}>
                <Field
                  auto={false}
                  isFullWidth
                  name='email'
                  component='text'
                  variant='filled'
                  size='lg'
                  placeholder='Email Address'
                  type='email'
                  autoFocus
                  mb={3}
                  color='primary'
                  border='solid 1px white !important'
                />
                <Field
                  auto={false}
                  isFullWidth
                  name='password'
                  component='text'
                  variant='filled'
                  size='lg'
                  placeholder='Password'
                  type='password'
                  mb={3}
                  color='primary'
                  border='solid 1px white !important'
                />

                <SubmitButton loading={loading} />
              </Form>
              <LinkButton to='forgot-password'>
                <Button
                  variant='ghost'
                  isFullWidth
                  variantColor='primary'
                  size='lg'
                >
                  Forgot Password
                </Button>
              </LinkButton>
            </Box>
          </Flex>

          {error && (
            <Alert status='error' variant='subtle'>
              {error?.response?.data?.error}
              {error?.response?.data?.errors?.message}
            </Alert>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};
export default Login;
