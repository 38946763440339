import React, { useEffect, useState, useCallback } from "react";
import {
  Text,
  Flex,
  Box,
  Progress,
  Button,
  Select,
} from "@builtbypixel/plasma";
import Cookie from "js-cookie";

import styled from "styled-components";
import { BsArrowRepeat } from "react-icons/bs";
import Pusher from "pusher-js";
import { useRecoilValue } from "recoil";
import { globalAtom } from "../../state/global";
import { useHttp } from "@builtbypixel/nucleus";
import { Detector } from "react-detect-offline";
import { triggerClientMessage } from "../../helpers/pusher";
const Http = useHttp();
let channel;
Pusher.logToConsole = true;

let authorizer = (channel, options) => {
  return {
    authorize: (socketId, callback) => {
      Http.get("/pusher/auth", {
        params: { socket_id: socketId, channel_name: channel.name },
      })
        .then((res) => {
          callback(null, res.data);
        })
        .catch((err) => {
          callback(new Error(`Error calling auth endpoint: ${err}`), {
            auth: "",
          });
        });
    },
  };
};

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: "eu",
  authorizer: authorizer,
});

const PresenterCard = ({
  online,
  startRecord,
  stopRecord,
  status,
  error,
  uploadProgress,
  cameraPermission,
  children,
  setSelectedResolution,
  selectedResolution,
}) => {
  const [pusherSubscribe, setPusherSubscribe] = useState(false);
  const [channelBound, setChannelBound] = useState(false);
  const { user, session } = useRecoilValue(globalAtom);

  const verify = useCallback(
    (
      channel,
      evTitle,
      user,
      status,
      error,
      uploadProgress,
      cameraPermission,
      selectedResolution
    ) =>
      triggerClientMessage(
        channel,
        evTitle,
        user,
        status,
        error,
        uploadProgress,
        cameraPermission,
        selectedResolution
      ),
    [uploadProgress, cameraPermission, error, status, selectedResolution]
  );

  useEffect(() => {
    if (session?.title) {
      const evTitle = session?.title?.replace(/[^A-Z0-9]/gi, "_");
      channel = pusher.subscribe(`private-${evTitle}`);
      console.log("^^^^^^^^^ A", channel);
      //on subscribe
      channel.bind("pusher:subscription_succeeded", function () {
        setPusherSubscribe(true);
        console.log("^^^^^^^^^ IS SUBSCRIVE SUCCESS", channel);

        channel.bind(`client-private-${evTitle}-${user?.id}`, function (data) {
          setChannelBound(true);
          console.log("^^^^^^^^^ IS BIND SUCCSSS", channel);

          if (data.ping) {
            verify(
              channel,
              evTitle,
              user,
              status,
              error,
              uploadProgress,
              cameraPermission,
              selectedResolution
            );
          } else if (data.record) {
            console.log("^^^^^^^^^ start recording", channel);

            startRecord();
          } else if (data.resolution) {
            console.log("^^^^^^^^^ change resolution", data?.resolution);
            Cookie.set("width", data?.resolution.width);
            Cookie.set("height", data?.resolution.height);
            window.location.reload();
          } else {
            console.log("^^^^^^^^^ stop recording", channel);

            stopRecord();
          }
        });
        verify(
          channel,
          evTitle,
          user,
          status,
          error,
          uploadProgress,
          cameraPermission,
          selectedResolution
        );
      });
    }
  }, [session]);

  useEffect(() => {
    if (pusherSubscribe) {
      const evTitle = session?.title?.replace(/[^A-Z0-9]/gi, "_");
    }
  }, [pusherSubscribe]);

  useEffect(() => {
    if (channelBound && user?.role?.name === "Presenter" && session?.title) {
      const evTitle = session?.title?.replace(/[^A-Z0-9]/gi, "_");
      verify(
        channel,
        evTitle,
        user,
        status,
        error,
        uploadProgress,
        cameraPermission,
        selectedResolution
      );
    }
  }, [
    channel,
    user,
    status,
    error,
    uploadProgress,
    cameraPermission,
    channelBound,
    selectedResolution,
  ]);

  return (
    <Flex
      direction="column"
      w="100%"
      templateRows="200px 30px 30px"
      rowGap={1}
      columnGap={1}
      borderRadius="6px"
      fontSize="0.9rem"
      fontWeight="600"
    >
      <Box borderRadius="0.5em" color="white">
        {children}
      </Box>
      <Box bg="global.elementBg">
        <Flex
          justifyContent="space-between"
          py="0.5em"
          borderBottom="1px solid #EEEEEE"
        >
          <Text pl="1em">Status</Text>{" "}
          <Text pr="1em">
            {online ? "Online" : "Offline"}-{status}
          </Text>
        </Flex>

        <Flex
          justifyContent="space-between"
          align="center"
          py="0.5em"
          borderBottom="1px solid #EEEEEE"
        >
          <Text pl="1em">Permission</Text>{" "}
          <Flex pr="1em" align="center" cursor="pointer">
            <Text pr="5px">{cameraPermission}</Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

const View = (props) => {
  return (
    <Detector
      render={({ online }) => (
        <Flex bg="global.elementBg" w="100%" mb="2em">
          <PresenterCard online={online} {...props}>
            {props.children}
          </PresenterCard>
        </Flex>
      )}
    />
  );
};

export default View;
