const menuLinks = [
  {
    group:"View session",
    link:'/view-session',
    permissions: ['Presenter','Observer'],
    items:[]
 },
  {
    group: "Help",
    items: [
      {
        title: "Technical Support",
        href: "/support",
        permissions: ['Presenter','Observer'],
      },

    ],
    permissions: ['Presenter','Observer'],
  },
  {
    group: "Sessions",
    items: [
      {
        title: "View All",
        href: "/sessions",
        permissions: ['Super Admin','Admin'],
      },
      {
        title: "Create New",
        href: "/sessions/create",
        permissions: ['Super Admin','Admin'],
      },
   
    ],
    permissions: ['Super Admin','Admin'],
  },
  {
    group:"Clients",
    link:'/clients',
    items:[],
    permissions: ['Super Admin','Admin'],
  },
  {
    group: "Users",
    items: [
      {
        title: "View users",
        href: "/users",
        permissions: ['Super Admin','Admin'],
      },
      {
        title: "Create user",
        href: "/users/create",
        permissions: ['Super Admin','Admin'],
      },
    ],
    permissions: ['Super Admin','Admin'],
  },
];

export default menuLinks;
