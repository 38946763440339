import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHttp } from "@builtbypixel/nucleus";
import { generateSlugFromSite } from "../../helpers";
import { Alert, Box } from "@builtbypixel/plasma";

const SessionLogin = () => {
  const { id, url } = useParams();
  const [status, setStatus] = useState("connecting...");
  const [statusType, setStatusType] = useState("info");
  const Http = useHttp();

  useEffect(() => {
    if (url) {
      const now = new Date();
      const slug = generateSlugFromSite();

      Http.post(
        `/auth/loginToken/${url}`,
        {},
        {
          headers: {
            Authorization: null,
          },
        }
      )
        .then((res) => {
          setStatus("Login successful, redirecting...");
          setStatusType("success");
          localStorage.setItem(
            `${slug}-token`,
            JSON.stringify({
              token: res.data.success.token,
              expiry: now.getTime() + 230400000, // Expires in 8 hours
            })
          );
          window.location = `/view-session/${id}`;
        })
        .catch((err) => {
          setStatusType("error");
          setStatus("Unable to login. Please try again");
        });
      //   }
    }
  }, [url]);
  return (
    <Box p='2em'>
      <Alert
        status={statusType}
        title={statusType.charAt(0).toUpperCase() + statusType.slice(1)}
      >
        {status}
      </Alert>
    </Box>
  );
};

export default SessionLogin;
