import React, { useState, useEffect } from "react";

import { useHttp } from "@builtbypixel/nucleus";
import ZoomWindow from "./ZoomWindow";

import { useRecoilState } from "recoil";
import { globalAtom } from "../../state/global";
import { useParams } from "react-router-dom";

const View = () => {
  const [user, setUser] = useRecoilState(globalAtom);
  const Http = useHttp();
  const { id } = useParams();

  const [hostSessionData, setHostSessionData] = useState(null);

  useEffect(() => {
    fetchRemoteVideos();
  }, []);
  const fetchRemoteVideos = () => {
    Http.get(`/sessions/${id}`).then((res) => {
      setHostSessionData(res.data.data);
    });
  };

  return (
    <>
      <ZoomWindow user={user?.user} hostSessionData={hostSessionData} />
    </>
  );
};

export default View;
