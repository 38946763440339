import React from "react";
import { Text, Flex } from "@builtbypixel/plasma";

const View = ({ title }) => {
  return (
    <>
      <Flex
        bg='global.elementBg'
        w='100%'
        h='60px'
        borderBottom='1px'
        borderColor='global.borderColour'
        align='center'
        px='20px'
        pr='35px'
        position='sticky'
        top={0}
        color='primary'
      >
        <Text fontSize='0.9rem' fontWeight='600'>
          {title}
        </Text>
      </Flex>
    </>
  );
};

export default View;
