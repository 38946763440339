const modelName = 'Users'
const modelNameSingular = 'User'
const endpoint = '/users'

const setup = {
   title: modelName,
   singular: modelNameSingular,
   model: modelName.replace(/\s+/g, '-').toLowerCase(),
   canDelete: true,
   canAddNew: true,
   canSearch: true,
   endpoint,
   accessor: 'id'
}

export default setup
