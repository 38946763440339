import React, { useState } from "react";
import HeaderBar from "../../components/HeaderBar";
import RightSidePanel from "../../components/RightSidePanel";
import { Flex } from "@builtbypixel/plasma";
import VideoCard from "../../components/VideoCard";

const View = () => {
  const [userRole, setUserRole] = useState("presenter");
  return (
    <>
      <Flex height={"100%"} width={"100%"} bg='global.elementBg'>
        <Flex flexDirection='column' width={"100%"}>
          <Flex>
            <HeaderBar
              title='View Individual Session!!'
              height='2em'
              width='auto'
              size='md'
              as='h4'
            ></HeaderBar>
          </Flex>
          <Flex height='100%'>
            <Flex width='100%' flexDirection='column'>
              <Flex
                m='1em'
                width='auto'
                height='50%'
                bg='global.elementBg'
                justifyContent='center'
              ></Flex>

              {userRole !== "presenter" && (
                <Flex
                  min-height={userRole !== "host" && "40%"}
                  height={userRole !== "observer" && "40%"}
                  justifyContent='space-between'
                  m='1em'
                  p='1em'
                >
                  <Flex w='30%'>
                    <VideoCard userRole={userRole}></VideoCard>
                  </Flex>
                  <Flex width='30%'>
                    <VideoCard userRole={userRole}></VideoCard>
                  </Flex>

                  <Flex w='30%'>
                    <VideoCard userRole={userRole}></VideoCard>
                  </Flex>
                </Flex>
              )}
            </Flex>
            <RightSidePanel userRole={userRole} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default View;
