const loadRecorder = (callback) => {
  const existingScript = document.getElementById('mediastream-js-cdn');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://www.webrtc-experiment.com/common.js';
    script.id = 'mediastream-js-cdn';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadRecorder;
