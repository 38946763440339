import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Flex } from "@builtbypixel/plasma";
//import { yupResolver } from '@hookform/resolvers/yup'

const Form = (props) => {
  const { children, onSubmit, data, validationSchema } = props;

  const methods = useForm({
    shouldUnregister: false,
    mode: "onSubmit",
    defaultValues: data || {},
    reValidateMode: "onBlur",
    shouldFocusError: false,
    getValues: true,
    // resolver: validationSchema ? yupResolver(validationSchema) : null
  });

  const submitForm = () => {
    //get filled values
    const val = methods.getValues({ nest: true });

    //trigger validation on fields
    methods.trigger().then((value) => {
      if (value) {
        onSubmit(val);
      }
    });
  };
  //(onSubmit ? onSubmit(data) : console.log(data))

  /* eslint-disable */
  React.useEffect(() => {
    methods.reset(data);
    return () => {
      methods.reset(null);
    };
  }, [data]);

  return (
    <FormProvider {...methods} submit={submitForm}>
      <Flex
        as='form'
        onSubmit={(e) => e.preventDefault()}
        w='100%'
        justify='center'
        direction='column'
        novalidate
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { submit: submitForm });
          }
          return child;
        })}
      </Flex>
    </FormProvider>
  );
};

export default Form;
