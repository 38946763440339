import axios from "axios";
// import { generateSlugFromSite } from "../helpers";

const environment = process.env.REACT_APP_ENVIRONMENT;

// const hasToken = JSON.parse(
//   localStorage.getItem(`${generateSlugFromSite()}-token`)
// )
// const site = JSON.parse(localStorage.getItem(`${generateSlugFromSite()}-site`))

// if (hasToken) {
//   axios.defaults.headers.common.Authorization = `Bearer ${hasToken.token}`
// }

let api = process.env.REACT_APP_API_DEVELOPMENT;
console.log(api, "API url");
if (environment === "development") {
  api = process.env.REACT_APP_API_DEVELOPMENT;
}
if (environment === "staging") {
  api = process.env.REACT_APP_API_STAGING;
}
if (environment === "production") {
  api = process.env.REACT_APP_API_PRODUCTION;
}

export const useHttp = () => {
  const baseApi = `${api}/`;
  axios.defaults.baseURL = api;
  axios.defaults.headers.common.Accept = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";

  return axios;
};
