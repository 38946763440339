import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Routes from "../routes";
import useSWR from "swr";
import { Flex } from "@builtbypixel/plasma";
import {
  saveSiteToStorage,
  checkAuthTokenExpiry,
  hasAuthToken,
} from "../helpers";
import SideBar from "../components/Sidebar";
import HeaderBar from "../components/HeaderBar";
import Menu from "../components/Menu";
import { useGet } from "../hooks";
import { globalAtom } from "../state/global";
import { useSetRecoilState } from "recoil";

const testUser = {
  first_name: "Test",
  last_name: "User",
  email: "test@test.com",
  profile_image: {
    url: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
  role: {
    permissions: ["Admin", "RDA"],
  },
};

const Kernel = (props) => {
  const { config, routes, links } = props;
  const setState = useSetRecoilState(globalAtom);
  //const location = useLocation();
  const skipCurrent = window.location.pathname.includes("login-session");
  useEffect(() => {
    // Set the site from config
    saveSiteToStorage(config.siteSwitcher.sites);
  }, [config.siteSwitcher.sites]);

  checkAuthTokenExpiry();

  const { data } = useSWR(
    hasAuthToken() && !skipCurrent ? "auth/current" : null,
    useGet
  );

  /* eslint-disable */
  useEffect(() => {
    if (data) {
      console.log(data?.data?.session, "mdatamm");

      setState((old) => ({
        ...old,
        config,
        routes,
        links,
        user: data?.data,
        session: data?.data?.session,
      }));
    }
    // else {
    //   if (process.env.REACT_APP_BYPASS_AUTH) {
    //     setState((old) => ({
    //       ...old,
    //       config,
    //       routes,
    //       links,
    //       user: testUser,
    //       session: { id: "mee" },
    //     }));
    //   }
    // }
  }, [config, routes, links, data]);

  return (
    <Router>
      <Flex w='100vw' h='100vh' maxHeight='100vh' overflow='hidden'>
        {hasAuthToken() && (
          <React.Fragment>
            <SideBar />
            <Menu links={links} user={data ? data?.data : testUser} />
          </React.Fragment>
        )}
        <Flex
          direction='column'
          w='100%'
          maxHeight='100vh'
          overflowX='hidden'
          //borderLeft='solid 1px #CBD5E0 !important'
          borderLeft='1px solid'
          borderColor='global.borderColour'
          //borderRight='solid 1px'
          //borderColor='global.borderColour'
        >
          <Routes clientRoutes={routes} />
        </Flex>
      </Flex>
    </Router>
  );
};

export default Kernel;
