import React, { useEffect } from "react";
import { Flex } from "@builtbypixel/plasma";
import { Helmet } from "react-helmet";
import { useSetRecoilState } from "recoil";
import { globalAtom } from "../../state/global";

const Page = (props) => {
  const { children, title, padding = true } = props;

  const setMenu = useSetRecoilState(globalAtom);

  useEffect(() => {
    setMenu((old) => ({ ...old, menu: false }));
  }, [setMenu]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Flex
        h='100vh'
        w='100%'
        direction='column'
        maxHeight='100vh'
        overflowY='auto'
        overflowX='hidden'
        color='primary'
        backgroundColor='global.elementBg'
        borderColor='white'
        // p={!padding ? '0px' : '20px'}
      >
        {children}
      </Flex>
    </React.Fragment>
  );
};

export default Page;
