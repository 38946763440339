import { atom } from 'recoil'

export const globalAtom = atom({
   key: 'globalState',
   default: {
      config: null,
      routes: null,
      links: [],
      user: null,
   session:null   }
})

export const menuAtom = atom({
   key: 'menuState',
   default: {
      menu: false
   }
})
export const defaultValues =  {
   search:'',
   selected: [],
   params: {
         pagination:10,
         page:1,
         search:''
     }

   }
export const tableAtom = atom({
   key: 'tableState',
   default: defaultValues
   
})

export const notificationsAtom = atom({
   key: 'notificationsState',
   default: []
   
})
