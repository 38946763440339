import View from "./View";
import Entry from "./";

const routes = [
  {
    path: "/view-session",
    title: "View Sessions",
    exact: true,
    auth: true,
    component: Entry,
    permissions: ['Super Admin','Admin', 'All','Presenter','Observer']
  },
  {
    path: "/view-session/:id",
    title: "View Session",
    exact: false,
    auth: true,
    component: View,
    permissions: ['Super Admin','Admin', 'All','Presenter','Observer']
  },
];

export default routes;
