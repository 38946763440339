import React, { useEffect, useState } from "react";
import { EditView, Field } from "@builtbypixel/nucleus";
import { Card, Flex, Button, Box, Text, useToast } from "@builtbypixel/plasma";
import setup from "./setup";
import { useHttp } from "@builtbypixel/nucleus";
import { useParams } from "react-router-dom";

const siteUrl = process.env.REACT_APP_SITE_URL;
const EditForm = ({ control }) => {
  const Http = useHttp();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    Http.get("/clients").then((res) => {
      setClients(res.data.data);
    });
  }, []);

  return (
    <EditView setup={setup}>
      <>
        <Card width='100%' p='1em' title='Session Details'>
          <Box my='2em' ml='1em'>
            <Field
              component='text'
              type='text'
              size='sm'
              path='/'
              name='title'
              placeholder='Enter Session Title'
              label='Sesson Title'
              isRequired={true}
              rules={{ required: true }}
            />
            <Field
              name='job_number'
              component='text'
              placeholder='Job Number'
              label='Job Number'
              isRequired={true}
              rules={{ required: true }}
            />
            <Field
              name='client'
              component='select'
              options={clients}
              labelKey='name'
              valueKey='id'
              label='Client'
              placeholder='Select client'
            />
            <Field
              name='team'
              component='text'
              placeholder='Enter Team'
              label='Team'
              type='text'
              isRequired={true}
              rules={{ required: true }}
            />
            <Field
              name='date'
              component='date'
              placeholder='Select Date'
              label='Date'
              type='text'
              isRequired={true}
              rules={{ required: true }}
            />

            <Field
              name='zoom_meeting_id'
              component='text'
              placeholder='0000 0000 0000'
              label='Zoom Meeting ID'
              type='number'
              isRequired={true}
              rules={{ required: true }}
            />

            <Field
              name='zoom_meeting_password'
              component='text'
              placeholder=''
              label='Zoom Meeting Password'
              type='text'
              isRequired={true}
              rules={{ required: true }}
            />
          </Box>
          <Box ml='1em'>
            <Field
              label='Presenters'
              component='repeater'
              type='number'
              size='sm'
              path='/'
              name='presenters'
              keyName='key'
              isRequired={true}
              rules={{ required: true }}
              fieldArray={[
                {
                  name: "name",
                  component: "text",
                  placeholder: "Name",
                  label: "Name",
                  isRequired: true,
                  rules: { required: true },
                },
                {
                  name: "link",
                  component: LinkField,
                  defaultValie: null,
                  conrol: control,
                  label: "Link",
                },
                {
                  name: "id",
                  component: HiddenId,
                  defaultValie: null,
                  width: "0px",
                },
              ]}
            />
          </Box>
        </Card>
      </>
    </EditView>
  );
};

const HiddenId = (item) => {
  return <Box display='none'></Box>;
};

const LinkField = (item) => {
  const { id } = useParams();
  const toast = useToast();

  const clickToCopy = (link_text) => {
    navigator.clipboard.writeText(
      siteUrl + "/login-session/" + link_text + "/" + id
    );
    toast({
      title: "Link Copied",
      status: "success",
      position: "top",
      variant: "plain",
    });
  };
  return item?.value ? (
    <Flex justify='center' align='center'>
      <Button h='2.3em' onClick={() => clickToCopy(item?.value)}>
        {siteUrl + "/login-session/" + item?.value + "/" + id}
      </Button>
    </Flex>
  ) : null;
};
export default EditForm;
