import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { useHttp } from '../../hooks'
import {
   Progress,
   Flex,
   Text,
   Image,
   Stack,
   Button,
   Icon,
   PseudoBox,
   Box,
   useToast
} from '@builtbypixel/plasma'
import { FiUploadCloud } from 'react-icons/fi'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { GrDrag } from 'react-icons/gr'
import { HiCheck } from 'react-icons/hi'
import { MdPictureAsPdf } from 'react-icons/md'
import ImagePreview from '../../components/common/ImagePreview'

const File = (props) => {
   const Http = useHttp()
   const toast = useToast()

   const {
      onChange,

      value,
      max = 5,
      isMultiple = false
   } = props

   const [uploaded, setUploaded] = React.useState(value ? value : [])
   const [selectedFiles, setSelected] = React.useState([])
   const [progressInfos, setProgressInfos] = React.useState([])

   useEffect(() => {
      setUploaded(value)
   }, [value])

   /* eslint-disable */

   const upload = () => {
      let _uploaded = []
      if (uploaded) {
         _uploaded = [...uploaded]
      }
      const uploaders = selectedFiles.map((file, idx) => {
         const formData = new FormData()
         formData.append('file', file)

         return Http.post(`/file-upload`, formData, {
            headers: {
               'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (ev) => {
               let _progressInfos = [...progressInfos]
               let item = _progressInfos[idx]

               const progress = (ev.loaded / ev.total) * 100
               Object.assign(item, { progress: progress })
               setProgressInfos(_progressInfos)
            }
         })
            .then((response) => {
               const { data } = response
               _uploaded.push(data)
            })
            .catch((err) => {
               toast({
                  title: `${err.response.status} - ${err.response.statusText}`,
                  message: err.response.data.exception
                     ? err.response.data?.exception
                     : err.response.data?.errors?.message,
                  status: 'error',
                  position: 'top-center',
                  variant: 'plain'
               })
            })
      })

      axios.all(uploaders).then(() => {
         onChange(_uploaded)
         setTimeout(() => {
            setSelected([])
         }, 500)
         setTimeout(() => {
            setUploaded(_uploaded)
         }, 700)
      })
   }

   const onDrop = useCallback(
      (acceptedFiles) => {
         const store = selectedFiles ? [...selectedFiles] : []
         setProgressInfos([...store, ...acceptedFiles])
         setSelected([...store, ...acceptedFiles])
      },
      [upload]
   )

   const uploadFiles = useCallback(() => {
      upload()
   }, [selectedFiles, upload])

   const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
   }

   const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
         return
      }

      const items = reorder(
         uploaded,
         result.source.index,
         result.destination.index
      )

      setUploaded(items)
      onChange(items)
   }

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      maxFiles: max,
      multiple: isMultiple,
      disabled: uploaded && uploaded.length >= max || selectedFiles.length >= max
   })
   return (
      <Flex direction='column' align='flex-start'>
         <div {...getRootProps()} mb='15px'>
            <input {...getInputProps()} />
            <Button
               leftIcon={<FiUploadCloud />}
               size='sm'
               isDisabled={uploaded &&
                  uploaded.length >= max || selectedFiles.length >= max
               }
            >
               Select Files
            </Button>
         </div>

         {selectedFiles && selectedFiles.length !== 0 && (
            <Stack spacing='3px' w='100%' mb='15px' mt='10px'>
               {selectedFiles &&
                  selectedFiles.map((progressInfo, index) => (
                     <PseudoBox
                        {...getQueueItemStyle()}
                        w='100%'
                        h='40px'
                        key={`selectedFiles-${index}`}
                     >
                        <Flex pr='10px' align='center' h='37px'>
                           {progressInfo.type === 'application/pdf' ? (
                              <Icon
                                 px='6px'
                                 fontSize='21px'
                                 h='37px'
                                 display='flex'
                                 alignItems='center'
                                 color='secondary'
                              >
                                 <MdPictureAsPdf />
                              </Icon>
                           ) : (
                                 <Image
                                    src={URL.createObjectURL(progressInfo)}
                                    objectFit='cover'
                                    size='40px'
                                    mr='20px'
                                 />
                              )}
                           <Text
                              isTruncated
                              maxWidth='200px'
                              fontWeight='bold'
                              fontSize='sm'
                           >
                              {progressInfo.name}
                           </Text>
                           <Text ml='auto' fontSize='12px' color='gray.400'>
                              Queued
                           </Text>
                        </Flex>
                        <Progress
                           height='4px'
                           value={
                              progressInfos[index].progress
                                 ? progressInfo.progress
                                 : 0
                           }
                        />
                     </PseudoBox>
                  ))}
            </Stack>
         )}

         {selectedFiles && selectedFiles.length !== 0 && (
            <Button
               leftIcon={<FiUploadCloud />}
               variantColor='primary'
               size='sm'
               onClick={() => uploadFiles()}
            >
               Upload Queued Files
            </Button>
         )}

         {uploaded && uploaded.length !== 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
               <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                     <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        w='100%'
                        mt='10px'
                        mb='10px'
                     >
                        {uploaded.map((item, index) => (
                           <Draggable
                              key={`file-${index}`}
                              draggableId={
                                 item.original_filename
                                    ? item.original_filename
                                    : `file-${index}`
                              }
                              index={index}
                           >
                              {(provided, snapshot) => (
                                 <Flex
                                    ref={provided.innerRef}
                                    align='center'
                                    justify='flex-start'
                                    {...provided.draggableProps}
                                    {...getItemStyle(
                                       snapshot.isDragging,
                                       provided.draggableProps.style
                                    )}
                                 >
                                    <Icon
                                       px='10px'
                                       color='global.text'
                                       {...provided.dragHandleProps}
                                    >
                                       <GrDrag color='global.text' />
                                    </Icon>
                                    {item.type === 'application/pdf' ? (
                                       <Icon
                                          px='6px'
                                          fontSize='21px'
                                          h='37px'
                                          display='flex'
                                          alignItems='center'
                                          color='secondary'
                                       >
                                          <MdPictureAsPdf />
                                       </Icon>
                                    ) : (
                                          <ImagePreview url={item.url}>
                                             <Image
                                                src={item.url}
                                                size='60px'
                                                objectFit='cover'
                                                mr='15px'
                                             />
                                          </ImagePreview>
                                       )}
                                    <Flex direction='column'>
                                       <Text
                                          maxWidth='200px'
                                          fontSize='12px'
                                          opacity={0.7}
                                          isTruncated
                                       >
                                          {item.original_filename}
                                       </Text>
                                       <Text
                                          fontWeight='semibold'
                                          fontSize='11px'
                                       >
                                          {item.format}
                                       </Text>
                                    </Flex>
                                    <Flex as='span' ml='auto' align='center'>
                                       <Icon color='success' mr='3px'>
                                          <HiCheck />
                                       </Icon>
                                       <Text
                                          color='success'
                                          fontWeight='semibold'
                                          fontSize='12px'
                                       >
                                          Uploaded
                                       </Text>
                                    </Flex>
                                 </Flex>
                              )}
                           </Draggable>
                        ))}
                        {provided.placeholder}
                     </Box>
                  )}
               </Droppable>
            </DragDropContext>
         )}
      </Flex>
   )
}

const getQueueItemStyle = () => ({
   // some basic styles to make the items look a bit nicer
   userSelect: 'none',
   display: 'flex',
   flexDirection: 'column',
   bg: 'global.elementBgAlt',
   border: '1px',
   borderColor: 'global.borderColour',
   rounded: 'lg',
   overflow: 'hidden'
})

const getItemStyle = (isDragging, draggableStyle) => ({
   // some basic styles to make the items look a bit nicer
   userSelect: 'none',
   mb: '10px',
   rounded: 'lg',
   boxShadow: 'md',
   border: '1px',
   borderColor: isDragging ? 'primary' : 'global.borderColour',
   bg: isDragging ? 'global.elementBgAlt' : 'global.elementBg',
   pr: '10px',
   ...draggableStyle
})

export default File
