import React, { useState, useEffect, useRef } from 'react';
import HeaderBar from '../../components/HeaderBar';
import RightSidePanel from '../../components/RightSidePanel';
import { Flex, Grid, Box, Text, Button } from '@builtbypixel/plasma';
import { useHttp } from '@builtbypixel/nucleus';
import Dexie from 'dexie';
import { useRecoilState } from 'recoil';
import { globalAtom } from '../../state/global';
import { useParams } from 'react-router-dom';
import DownloadModal from '../../components/DownloadModal';
import HostView from '../../components/HostView';
import LinkButton from '../../components/LinkButton';
import ZoomWindow from '../ZoomPage/ZoomWindow';
import PixelMediaRecorder from '../../components/PixelMediaRecorder';

const View = () => {
  const [user, setUser] = useRecoilState(globalAtom);
  const Http = useHttp();
  const db = new Dexie('RemoteRecord');
  const { id } = useParams();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [trackUploadComplete, setTrackUploadComplete] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [hostSessionData, setHostSessionData] = useState(null);
  const [existingLocal, setExistingLocal] = useState(false);
  const [streamRef, setStreamRef] = useState(Date.now());
  //const [uploadQueue, setUploadQueue] = useState([]);
  const fetchRemoteVideos = () => {
    Http.get(`/sessions/${id}`).then((res) => {
      setHostSessionData(res.data.data);
    });
  };

  useEffect(() => {
    fetchRemoteVideos();
    //  setStreamRef(Date.now());
  }, []);

  useEffect(
    () => {
      console.log('!!!!! a', db.eventRecording?.value);

      // create the store
      if (db && user?.session) {
        console.log('!!!!! b');

        db.version(3).stores({ eventRecording: 'id,value' });
        db.transaction('rw!', db.eventRecording, async () => {
          console.log('!!!!! c');

          const existing = await db.eventRecording.get(user?.session?.title);

          if (!existing || existing?.value?.length === 0) {
            console.log('!!!!! no have local');

            await db.eventRecording.add({
              id: user?.session?.title,
              value: [],
            });
          } else {
            if (existing?.value.length > 0 && !uploadInProgress) {
              console.log('!!!!! do do have local');

              // const webm = existing?.value.reduce(
              //   (a, b) =>
              //     new Blob([a, b], {
              //       type: 'video/x-matroska;codecs=avc1,opus',
              //     }),
              // );

              // download(webm);
              // // });
              setExistingLocal(true);
              // // checkExistingLocal();
              doUpload(existing?.value);
            }
          }
        }).catch((e) => {
          // log any errors
          console.log(e.stack || e);
        });
      }
      // return () => db.close();
    },
    // run effect whenever the database connection changes
    [db.eventRecording, user],
  );

  async function saveToLocal(recordedChunks) {
    console.log('save to local11111!!!!!!!!!!!!!!!!!!!!!!!!!');
    var blob = new Blob([recordedChunks], {
      type: 'video/x-matroska;codecs=avc1,opus',
    });
    // download(blob);

    const arrayBufferVideo = await db.eventRecording.get(user?.session?.title);

    let pushedVal = arrayBufferVideo.value;
    pushedVal.push(blob);

    db.eventRecording
      .put({ id: user?.user?.session.title, value: pushedVal })
      .then((res) => {
        // setExistingLocal(true);
        // doUpload(blob);
        handleUploadToRemote();
        setExistingLocal(blob);
      });

    // download(webm);
    // function blobToArrayBuffer(blob, callback) {
    //   var reader = new FileReader();
    //   reader.readAsArrayBuffer(blob);
    //   reader.onload = function (e) {
    //     callback({
    //       buffer: e.target.result,
    //       type: blob.type,
    //     });
    //   };
    // }

    // //convert to array buffer for local storage
    // blobToArrayBuffer(blob, saveToBrowserCallback);
  }

  const handleDownloadFromRemote = async (url) => {
    console.log(url, 'URURURURU');
    const blobs = await Promise.all(
      url.map(
        async (item) =>
          await Http.get(item, {
            responseType: 'blob',
            timeout: 30000,
          }).then((res) => {
            return res.data;
          }),
      ),
    );

    const webm = blobs.reduce(
      (a, b) => new Blob([a, b], { type: 'video/x-matroska;codecs=avc1,opus' }),
    );

    download(webm);
    // });
  };

  const download = (webm) => {
    var url = URL.createObjectURL(webm);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = 'test.webm';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleUploadToRemote = async () => {
    const arrayBufferVideo = await db?.eventRecording?.get(
      user?.session?.title,
    );

    console.log(arrayBufferVideo?.value);
    if (arrayBufferVideo?.value?.length > 0 && !uploadInProgress) {
      doUpload(arrayBufferVideo.value);
    } else {
      setExistingLocal(false);
    }
  };

  const doUpload = (uploadQueue) => {
    console.log('!!!!!! doUpload', uploadQueue);
    setUploadInProgress(true);
    //prepare submission form
    const data = new FormData();
    //set first item in array
    console.log(typeof uploadQueue[0], '!!!! tyepof', uploadQueue[0]);
    if (typeof uploadQueue[0] === 'object') {
      data.append('blobby', uploadQueue[0], 'file.name');
      data.append('stream_ref', streamRef);

      //post data
      Http.post(`/video-upload/${id}/${user?.user?.id}`, data, {
        headers: {
          'content-type': `multipart/form-data; boundary=${data._boundary}`,
          // Authorization: `Bearer ${token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setUploadProgress(percentCompleted);
        },
      }).then((res) => {
        console.log(
          'SUCCESS UPLOAD !!!!!!!',
          uploadQueue,
          db.eventRecording,
          //    uploadQueue.slice(1, uploadQueue.length),
        );
        setUploadInProgress(false);

        const slideduploadQueue = uploadQueue.slice(1, uploadQueue.length);
        db.transaction('rw!', db.eventRecording, async () => {
          db.eventRecording
            .put({
              id: user?.user?.session.title,
              value: slideduploadQueue,
            })
            .then((res) => {
              console.log('!!!!!! put and uplodad', slideduploadQueue);
              doUpload(slideduploadQueue);

              //   setExistingLocal(true);
            })
            .catch((err) => console.log('cannot db save', err));
        });
      });
    }
  };

  const checkExistingLocal = async () => {
    const arrayBufferVideo = await db.eventRecording.get(user?.session?.title);
    console.log('Checking existing logacl !!!!!!', arrayBufferVideo.value);

    if (arrayBufferVideo.value.length > 0) {
      handleUploadToRemote();
      setExistingLocal(arrayBufferVideo.value[0]);
      // setUploadQueue(arrayBufferVideo.value);
    }
  };

  useEffect(() => {
    if (existingLocal) {
      console.log('trigger refresh');
      handleUploadToRemote();
    }
  }, [existingLocal]);

  return (
    <>
      <Flex width={'100%'} backgroundColor="global.elementBg">
        <Flex flexDirection="column" width={'100%'}>
          <Flex>
            <HeaderBar
              title={
                hostSessionData ? hostSessionData?.title : user?.session?.title
              }
              height="2em"
              width="auto"
              size="md"
              as="h4"
            ></HeaderBar>
          </Flex>
          <Grid height="100%" templateColumns="3fr 1fr">
            <Flex width="100%" flexDirection="column">
              {user?.user?.role?.name === 'Presenter' && (
                <PixelMediaRecorder
                  trackUploadComplete={trackUploadComplete}
                  uploadProgress={uploadProgress}
                  saveToLocal={saveToLocal}
                  checkExistingLocal={checkExistingLocal}
                  setStreamRef={setStreamRef}
                />
              )}

              {user?.user?.role?.name !== 'Super Admin' ? (
                <ZoomWindow
                  user={user?.user}
                  hostSessionData={hostSessionData}
                />
              ) : (
                <>
                  <Box m="1em" bg="global.elementBg">
                    <LinkButton to={`/presentation/${id}`} target="_blank">
                      <Button
                        variant="solid"
                        isFullWidth
                        justifyContent="center"
                        size="lg"
                        mb="5px"
                      >
                        <Text>Join Zoom Meeting</Text>
                      </Button>
                    </LinkButton>
                  </Box>
                  <HostView
                    hostSessionData={hostSessionData}
                    handleDownloadFromRemote={handleDownloadFromRemote}
                    fetchRemoteVideos={fetchRemoteVideos}
                  />
                </>
              )}
            </Flex>
            <RightSidePanel
              userRole={user?.user?.role?.name}
              session={hostSessionData ? hostSessionData : user?.session}
              hostSessionData={hostSessionData}
            >
              {/* <DownloadModal
                remoteRecordings={transformArr(hostSessionData?.videos, user)}
                handleDownloadFromRemote={handleDownloadFromRemote}
              /> */}
            </RightSidePanel>
          </Grid>
        </Flex>
      </Flex>
    </>
  );
};

export default View;

/*[
  {type: 'fruit', foods: ['apple', 'banana']},
  {type: 'vegetable', foods: ['potato']}
]*/

// const transformArr = (hostSessionData, user) => {
//   const orig = hostSessionData?.presenters?.find(
//     (el) => el.id === user?.user?.id,
//   );
//   console.log(orig, 'OROROROROR');
//   var newArr = [],
//     types = {},
//     i,
//     j,
//     cur;
//   for (i = 0, j = orig?.length; i < j; i++) {
//     cur = orig[i];
//     if (!(cur.stream_ref in types)) {
//       types[cur.stream_ref] = { type: cur.stream_ref, foods: [] };
//       newArr.push(types[cur.stream_ref]);
//     }
//     types[cur.stream_ref].foods.push(cur.food);
//   }
//   return newArr;
// };
