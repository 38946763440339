import React, { useState, useEffect } from 'react';
import { useHttp } from '@builtbypixel/nucleus';
import {
  Box,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Button,
} from '@builtbypixel/plasma';
import './css/bootstrap.css';
import './css/react-select.css';
import './css/custom-zoom.css';

const API_KEY = process.env.REACT_APP_ZOOM_API;

const Zoom = ({ user, hostSessionData }) => {
  const [theZoom, setTheZoom] = useState(null);
  const [session, setSession] = useState(null);

  useEffect(() => {
    if (hostSessionData) {
      setSession(hostSessionData);
    }
  }, [hostSessionData]);

  useEffect(() => {
    if (user?.session) {
      setSession(user.session);
    }
  }, [user]);

  useEffect(() => {
    import('@zoomus/websdk').then((module) => {
      const { ZoomMtg } = module;
      setTheZoom(ZoomMtg);
      // ZoomMtg.setZoomJSLib('/js', '/av');
      ZoomMtg.setZoomJSLib('https://source.zoom.us/2.5.0/lib', '/av');
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareJssdk();
    });
  }, []);
  return <ZoomLoaded session={session} ZoomMtg={theZoom} user={user} />;
};
const ZoomLoaded = ({ ZoomMtg, session, user }) => {
  const Http = useHttp();

  const [zoomUser, setZoomUser] = useState(null);
  const [zoomEmail, setZoomEmail] = useState(null);
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (user?.role?.name === 'Observer') {
      setZoomUser(null);
      setZoomEmail(null);
      setShowForm(true);
    } else if (user) {
      setZoomUser(user?.name);
      setZoomEmail(user?.email);
    }
  }, [user]);

  useEffect(() => {
    if (zoomUser && ZoomMtg && user?.role?.name !== 'Observer') {
      initMeet();
    }
  }, [ZoomMtg, zoomUser, user]);

  const initMeet = () => {
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    setShowForm(false);
    const payload = {
      meeting_id: session?.zoom_meeting_id,
      user_role: 0,
      api_key: API_KEY,
    };
    Http.post('/signature', payload)
      .then((res) => {
        startZoom(res.data.signature);
      })
      .catch((err) => {
        console.log('no meeting signature', err);
      });
  };
  const startZoom = (signature) => {
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    setMeetingStarted(false);
    ZoomMtg.init({
      debug: true,
      leaveUrl: `/view-session/${session?.id}`,
      isSupportAV: true,
      // disableCORP: !window.crossOriginIsolated, // default true
      success(res) {
        ZoomMtg.join({
          meetingNumber: session?.zoom_meeting_id,
          userName: zoomUser,
          userEmail: zoomEmail,
          passWord: session?.zoom_meeting_password,
          apiKey: API_KEY,
          signature: signature,
          success: function (res) {
            setMeetingStarted(true);
          },
          error: function (res) {
            console.log(res);
            setMeetingStarted(false);
          },
        });
      },
      error(res) {
        setMeetingStarted(false);
      },
    });
  };

  return (
    <>
      <Box
        className="zoom-container meeting-started"
        position="relative"
        height={meetingStarted ? 'auto !important' : '0 !important'}
      ></Box>

      {/* <Box class="ReactModal__Body--open">

        <div class="ReactModalPortal"></div>
        <div class="ReactModalPortal"></div>
        <div class="ReactModalPortal"></div>
        <div class="global-pop-up-box"></div>
        <div class="sharer-controlbar-container sharer-controlbar-container--hidden"></div> */}

      <Box zIndex={showForm ? -1 : 9993} id="zmmtg-root"></Box>
      <Box id="aria-notify-area"></Box>

      {/* </Box> */}

      <Flex
        height="250px"
        justify="center"
        align="center"
        display={meetingStarted ? 'none' : 'flex'}
      >
        <Flex
          direction="column"
          width="100%"
          maxWidth="660px"
          borderColor="primary"
          borderRadius="5px"
          display={showForm ? 'flex' : 'none'}
        >
          {user?.role?.name === 'Observer' && (
            <>
              <FormControl
                help="We'll never share your email."
                label="Email Address"
              >
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  name="name"
                  placeholder="Name"
                  type="text"
                  value={zoomUser}
                  onChange={(e) => setZoomUser(e.target.value)}
                  width="100%"
                  maxWidth="660px"
                />
              </FormControl>

              <FormControl
                help="We'll never share your email."
                label="Email Address"
                mt="1em"
              >
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={zoomEmail}
                  onChange={(e) => setZoomEmail(e.target.value)}
                  width="100%"
                  maxWidth="660px"
                />
              </FormControl>
              <Button
                disabled={!zoomEmail && !zoomUser}
                onClick={() => initMeet()}
                mt="2em"
              >
                Join Meeting
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Zoom;
