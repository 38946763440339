import Entry from './'

const otherRoutes = [
   
        {
        path: '/login-session/:url/:id',
        title: 'Session Login',
        exact: true,
        auth: false,
        component: Entry,
    },
    
]

const routes = [ ...otherRoutes]

export default routes
