import React, { useEffect, useContext } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalButton,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalContext,
  Text,
  Icon,
  Box,
  ModalFooter,
} from '@builtbypixel/plasma';
import moment from 'moment';

import { FiClock } from 'react-icons/fi';

const DownloadModal = ({
  isHost,
  remoteRecordings,
  handleDownloadFromRemote,
}) => {
  return (
    <Box>
      <Modal color="primary">
        {remoteRecordings?.length > 0 && (
          <ModalButton w="100%">
            <Button w="100%">
              View {isHost ? 'presenter ' : 'my '}
              {remoteRecordings?.length === 1 ? 'video' : 'videos'}
            </Button>
          </ModalButton>
        )}
        <ModalContent border="1px solid" borderColor="global.borderColour">
          <ModalHeader color="primary">Select Recording</ModalHeader>
          <ModalBody>
            {remoteRecordings?.map((item, index) => {
              const date = new Date(parseInt(item.type));
              return (
                <Flex
                  justify="space-between"
                  borderTop={index % 1 === 0 && '1px gray solid'}
                  borderBottom={
                    index === remoteRecordings?.length - 1 && '1px gray solid'
                  }
                  px="1em"
                  color="primary"
                >
                  <Flex align="center">
                    <Icon mr="1em">
                      <FiClock></FiClock>
                    </Icon>
                    Recording {index + 1} -{' '}
                    {item.type &&
                      date.getDate() +
                        '/' +
                        (date.getMonth() + 1) +
                        '/' +
                        date.getFullYear() +
                        ' ' +
                        date.getHours() +
                        ':' +
                        date.getMinutes()}
                  </Flex>
                  <Button
                    variant="outline"
                    my="3px"
                    onClick={() => handleDownloadFromRemote(item.url)}
                  >
                    Download
                  </Button>
                </Flex>
              );
            })}
          </ModalBody>
          <ModalFooter showCloseIcon color="primary" />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DownloadModal;
