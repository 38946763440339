import Entry from './'




const otherRoutes = [
   
        {
        path: '/support',
        title: 'Technical Support',
        exact: true,
        auth: false,
        component: Entry,
        permissions: ['Admin', 'All']
    },
    
]

const routes = [ ...otherRoutes]

export default routes
