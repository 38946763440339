import React from "react";
import NucleusCore from './Core'
import { theme } from "./theme";
import config from "./config.json";
import routes from './routes/routes'
import links from "./links";


const App = () => {


  return (
      <NucleusCore
        config={config}
        routes={routes}
        links={links}
        override={theme}
      />
  );
};

export default App;
