import View from "./View";

const routes = [
  {
    path: "/presentation/:id",
    title: "Presentation",
    exact: true,
    auth: true,
    component: View,
    permissions: ['Super Admin','Admin', 'All','Presenter','Observer']
  }
];

export default routes;
