import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";

import setup from "./setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "Main Details",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "Name",
            label: "Name",
            isRequired: true,
            rules: { required: true },
          },
          {
            name: "email",
            component: "text",
            placeholder: "Email Address",
            label: "Email Address",
            type: "email",
            isRequired: true,
            rules: { required: true, email: true },
          },
          {
            name: "role",
            component: "select",
            disabled: true,
            value: {
              id: 1,
              name: "Super Admin",
            },
            options: [
              {
                id: 1,
                name: "Super Admin",
              },
            ],
            labelKey: "name",
            valueKey: "id",
            placeholder: "Role",
            label: "Role",
            type: "text",
            isRequired: true,
            rules: { required: true },
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
