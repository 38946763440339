import React from "react";
import { Flex, Image } from "@builtbypixel/plasma";

const View = ({
  image,
  width = "100%",
  height = "100%",
  padding,
  margin,
  background,
  userRole,
}) => {
  return image ? (
    <Flex my='1em'>
      <Flex
        p='1em'
        bg='black'
        m='1em'
        bg={background}
        justifyContent='centre'
        alignItems='centre'
        w='100%'
      >
        <Image w={width} h={height} src={image} p={padding} m={margin} />
      </Flex>
    </Flex>
  ) : null;
};

export default View;
