import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHttp } from "@builtbypixel/nucleus";
import { useRecoilValue } from "recoil";
import { globalAtom } from "../../state/global";

const SessionLogin = () => {
  const { id, url } = useParams();
  const [status, setStatus] = useState("connecting...");
  const Http = useHttp();
  const { session } = useRecoilValue(globalAtom);

  useEffect(() => {
    if (session?.id) {
      window.location = `/view-session/${session.id}`;
    }
  }, [session]);
  return "connecting...";
};

export default SessionLogin;
