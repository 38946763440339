import React from "react";
import { ThemeProvider } from "@builtbypixel/plasma";
import Kernel from "./Kernel";
import { SWRConfig } from "swr";
import { RecoilRoot } from "recoil";
import "./zoom.css";

const NucleusCore = (props) => {
  const { routes, config, links, override } = props;

  // import("@zoomus/websdk").then((module) => {
  //   const { ZoomMtg } = module;

  //   ZoomMtg.preLoadWasm();
  //   ZoomMtg.prepareJssdk();
  // }, []);
  return (
    <RecoilRoot>
      <ThemeProvider override={override}>
        <SWRConfig
          value={{
            refreshInterval: 0,
            revalidateOnFocus: false,
            shouldRetryOnError: false,
          }}
        >
          <Kernel config={config} routes={routes} links={links} />
        </SWRConfig>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default NucleusCore;
