export const theme = {
  
  colors: {
    mio:'yellow',
    primary: "#18191a",
    secondary: "#f1675c",
    progressBar: "#1e90ff",
    warning: "#E95C7B",
    global: {
      bg: "#f0f2f5",
      lightBg: "#ffffff",
    },
    modes: {
      dark: {
        mio:'yellow',

        primary: "rgba(255,255,255,0.9)",
        secondary: "#f1675c",
        progressBar: "white",

        global: {
          bg: "#0F0F12",
          lightBg: "#1A1A1E",
        },
      },
    },
  },
  fonts: {
    heading: `"Oxygen",-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `"Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    regular: `"Inter","Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  shadows: {
    card: "none",
  },
};
