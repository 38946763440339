const modelName = "Sessions";
const modelNameSingular = "Session";
const endpoint = "/sessions";

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, "-").toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  // accessor: 'id'
  accessor: "job_number",
};

export default setup;
