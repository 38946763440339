import React from "react";
import {
  Text,
  Flex,
  Alert,
  Input,
  Button,
  useToast,
  Box,
} from "@builtbypixel/plasma";
import Logo from "../Logo";
import VideoCard from "../VideoCard";

const siteUrl = process.env.REACT_APP_SITE_URL;

const HostView = ({ session }) => {
  const toast = useToast();

  const clickToCopy = (link_text) => {
    navigator.clipboard.writeText(
      siteUrl + "/login-session/" + link_text + "/" + session.id
    );
    toast({
      title: "Link Copied",
      status: "success",
      position: "top",
      variant: "plain",
    });
  };
  return (
    <Flex direction='column' color='primary'>
      <Text fontSize='1.5rem' fontWeight='600'>
        Session Details
      </Text>

      <Text mt='0.5em' fontSize='0.9rem' fontWeight='600'>
        Zoom Meeting ID
      </Text>
      <Input minHeight='2rem' disabled value={session?.zoom_meeting_id}></Input>
      <Text mt='0.5em' fontSize='0.9rem' fontWeight='600'>
        Zoom Password
      </Text>
      <Input
        minHeight='2rem'
        mb='2rem'
        disabled
        value={session?.zoom_meeting_password}
      ></Input>
      {session?.presenters?.length > 0 &&
        session?.presenters?.map((presenter, i) => (
          <Box>
            <Text mt='0.5em' fontSize='0.9rem' fontWeight='600'>
              {i + 1}. {presenter.name}
            </Text>
            <Flex>
              <Input
                minHeight='2rem'
                disabled
                value={
                  siteUrl +
                  "/login-session/" +
                  presenter.link +
                  "/" +
                  session?.id
                }
                color='primary'
                w='70%'
                mr='0.5em'
              />
              <Button w='30%' onClick={() => clickToCopy(presenter.link)}>
                Copy
              </Button>
            </Flex>
          </Box>
        ))}

      <Text mt='0.5em' fontSize='0.9rem' fontWeight='600'>
        Observer Link
      </Text>
      <Flex>
        <Input
          minHeight='2rem'
          disabled
          value={
            siteUrl +
            "/login-session/" +
            session?.observer?.link +
            "/" +
            session?.id
          }
          w='70%'
          mr='0.5em'
        />
        <Button w='30%' onClick={() => clickToCopy(session?.observer?.link)}>
          Copy
        </Button>
      </Flex>
    </Flex>
  );
};

const View = ({ userRole, children, session, hostSessionData }) => {
  return (
    <Flex
      bg='global.elementBg'
      pr='1em'
      pb='1em'
      pl='1em'
      h='100%'
      // minHeight='100vh'
      direction='column'
      borderLeft='solid 1px '
      borderBottom='solid 1px '
      borderColor='global.borderColour'
    >
      <Logo
        background='transparent'
        image={
          hostSessionData
            ? hostSessionData?.client?.image?.[0]?.url
            : session?.client?.image?.[0]?.url
        }
      />
      {userRole === "Presenter" && children}
      {userRole === "Super Admin" && (
        <HostView userRole={userRole} session={hostSessionData} />
      )}
    </Flex>
  );
};

export default View;
