import React, { memo, useRef } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Button,
  Image,
  Stack,
  useColorMode,
  Icon,
  Text,
} from "@builtbypixel/plasma";
import { globalAtom } from "../../state/global";
import { menuAtom } from "../../state/global";

import { useRecoilState, useRecoilValue } from "recoil";
import LinkButton from "../LinkButton";
import { useRouteMatch } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import { checkPermissions, getSite } from "../../helpers";
import { useOutsideClick } from "../../hooks";

const MenuLinkButton = memo(({ to, children, target, leftIcon }) => {
  const match = useRouteMatch({
    path: to,
    exact: true,
  });

  return (
    <LinkButton to={to}>
      <Button
        variant={match ? "solid" : "ghost"}
        isFullWidth
        justifyContent='flex-start'
        rightIcon={target && <FiArrowUpRight />}
        leftIcon={
          <Icon fontSize='20px' color={match ? "red" : "menuIconColour"}>
            {leftIcon}
          </Icon>
        }
        size='md'
        color={match && "red"}
        mb='5px'
      >
        <Text>{children}</Text>
      </Button>
    </LinkButton>
  );
});

const Menu = ({ links, user }) => {
  const site = getSite;
  const { colorMode } = useColorMode();
  const global = useRecoilValue(globalAtom);
  const [menu, setMenu] = useRecoilState(menuAtom);
  const menuRef = useRef();

  useOutsideClick(menuRef, () => setMenu({ menu: false }));

  const hasPermission = (permissions) => {
    if (user) {
      return checkPermissions({
        has: user?.role?.name,
        required: permissions,
      });
    } else {
      return false;
    }
  };

  return (
    <>
      <Box
        h='100vh'
        maxHeight='100vh'
        w='200px'
        minWidth='200px'
        py={2}
        px={3}
        // overflowY='scroll'
        bg='global.elementBg'
        position={{ xs: "fixed", md: "relative" }}
        left={0}
        top={0}
        zIndex={9001}
        transform={{
          xs: menu.menu ? "translateX(0%)" : "translateX(-100%)",
          md: "none",
        }}
        transition='0.3s all ease-in-out'
        ref={menuRef}
        border='none'
      >
        <Stack px={3} spacing='2px' my='20px'>
          <Image w='100px' objectFit='contain' src='/images/aps_logo.png' />
        </Stack>
        <Box color='primary'>
          {links &&
            links.map((group, groupIndex) =>
              group.items && group.items.length === 0
                ? hasPermission(group.permissions ? group.permissions : []) && (
                    <MenuLinkButton
                      key={`group--${groupIndex}`}
                      to={group.link}
                      target={group.target}
                      leftIcon={group.icon && group.icon}
                    >
                      {group.group}
                    </MenuLinkButton>
                  )
                : hasPermission(group.permissions ? group.permissions : []) && (
                    <Accordion
                      key={`${group.group}-${groupIndex}`}
                      collapsible
                      multiple
                    >
                      <AccordionItem>
                        <AccordionButton arrowAlign='right'>
                          {group.group}
                        </AccordionButton>
                        <AccordionPanel px={0}>
                          {group.items &&
                            group.items.map(
                              (item, index) =>
                                hasPermission(
                                  item.permissions ? item.permissions : []
                                ) && (
                                  <MenuLinkButton
                                    target={item.target}
                                    to={item.href}
                                    key={`${item.title}-${index}-${groupIndex}`}
                                    color='yellow'
                                  >
                                    {item.title}
                                  </MenuLinkButton>
                                )
                            )}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  )
            )}
        </Box>
      </Box>
      <Box
        w='100vw'
        h='100vh'
        bg='rgba(0,0,0,0.2)'
        position='fixed'
        zIndex={9000}
        content="' '"
        display={menu.menu ? "block" : "none"}
      />
    </>
  );
};

export default Menu;
