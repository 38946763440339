import React, { useMemo, useState } from "react";
import { useHttp } from "@builtbypixel/nucleus";
import { Flex, Input, TextArea, Box, Text, Button } from "@builtbypixel/plasma";
import HeaderBar from "../../components/HeaderBar";
import { useForm, Controller } from "react-hook-form";

const EditForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const { register, handleSubmit, control, watch, setValue } = useForm();
  const watchAllFields = watch();
  const Http = useHttp();
  const doSubmit = (data) => {
    Http.post("/support", data)
      .then((res) => {
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Flex height={"100%"} width={"100%"} bg='global.elementBg'>
      <Flex flexDirection='column' width={"100%"}>
        <Flex>
          <HeaderBar
            title='Need help?'
            height='2em'
            width='auto'
            size='md'
            as='h4'
          ></HeaderBar>
        </Flex>
        <Flex height='100%'>
          <Flex width='100%' flexDirection='column' p='20px'>
            {submitted ? (
              <Box>
                <Text as='h2' fontWeight='bold' mb='1em'>
                  Form submitted!
                </Text>
                <Text>We will be in touch with you shortly</Text>
              </Box>
            ) : (
              <Flex w='100%' maxWidth='450px' direction='column'>
                <Text as='h2' fontWeight='bold' mb='1em'>
                  Contact us now
                </Text>

                <form
                  onSubmit={handleSubmit(doSubmit)}
                  style={{ width: "100%" }}
                >
                  <Controller
                    render={({ field }) => (
                      <Input
                        type='text'
                        placeholder='Full Name'
                        label='Full Name'
                        name='name'
                        required
                        value={watchAllFields.name}
                        onChange={(e) => setValue("name", e.target.value, true)}
                        onBlur={(e) => setValue("name", e.target.value, true)}
                        ref={register({ required: true })}
                        my='0.5em'
                      />
                    )}
                    control={control}
                    name='name'
                  />
                  <Controller
                    render={({ field }) => (
                      <Input
                        type='email'
                        placeholder='Email'
                        label='Email'
                        name='email'
                        required
                        my='0.5em'
                        value={watchAllFields.email}
                        onChange={(e) =>
                          setValue("email", e.target.value, true)
                        }
                        onBlur={(e) => setValue("email", e.target.value, true)}
                        ref={register({ required: true })}
                      />
                    )}
                    control={control}
                    name='email'
                  />
                  <Controller
                    render={({ field }) => (
                      <TextArea
                        my='0.5em'
                        type='text'
                        placeholder='Get in touch with us now'
                        label='Message'
                        name='message'
                        required
                        value={watchAllFields.message}
                        onChange={(e) =>
                          setValue("message", e.target.value, true)
                        }
                        onBlur={(e) =>
                          setValue("message", e.target.value, true)
                        }
                        ref={register({ required: true })}
                      />
                    )}
                    control={control}
                    name='message'
                  />
                  <Button type='submit'>Submit</Button>
                </form>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EditForm;
