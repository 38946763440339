import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";

import setup from "./setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "Main Details",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "Client Name",
            label: "Client Name",
            isRequired: true,
            rules: { required: true },
          },
          {
            name: "image",
            component: "file",
            placeholder: "Logo",
            label: "Logo",
            isRequired: true,
            rules: { required: true },
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
