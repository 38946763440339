import React, { useState, useEffect, useRef } from 'react';
import VideoCard from '../../components/VideoCard';
import { useRecoilState } from 'recoil';
import { globalAtom } from '../../state/global';
import Cookie from 'js-cookie';
import RecordRTC from 'recordrtc';
import LoadRecorder from './LoadRecorder';

const PixelMediaRecorder = ({
  trackUploadComplete,
  uploadProgress,
  saveToLocal,
  checkExistingLocal,
  setStreamRef,
}) => {
  const [user, setUser] = useRecoilState(globalAtom);
  const [cameraPermission, setCameraPermission] = useState('requested');
  const [error, setError] = useState(null);
  const [mediaRecorderStatus, setMediaRecorderStatus] = useState('inactive');
  const [selectedResolution, setSelectedResolution] = useState({
    width: 32,
    height: 24,
  });
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    LoadRecorder(() => {
      setLoaded(true);
    });
  });

  //create a ref for mediarecorder
  const mediaRecorderRef = useRef([]);

  navigator.permissions.query({ name: 'camera' }).then((res) => {
    setCameraPermission(res.state);
  });

  //get access to camera and start stream
  function getAccess(res) {
    //if webRTC is supported
    if (navigator.getUserMedia) {
      // this allows for .then async
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: {
            width: { exact: res.width },
            height: { exact: res.height },
          },
        })
        .then(
          (videoStream) => {
            console.log(selectedResolution, '<----- selected RES');
            let mediaRecorder;

            var options = {
              recorderType: window.MediaStreamRecorder,
              audioBitsPerSecond: 128000,
              videoBitsPerSecond: 2500000,
              mimeType: 'video/webm;codecs=h264',
              timeSlice: 30000,
              ondataavailable: handleDataAvailable,
            };

            try {
              mediaRecorder = new RecordRTC(videoStream, options);
              mediaRecorderRef.current.push(mediaRecorder);
            } catch (err) {
              setError('ER01: Can not create MediaRecorder');
            }

            //clear errors on record
            mediaRecorder.onstart = function () {
              setError(null);
              setMediaRecorderStatus('recording');
            };

            //for some reason better than on stop for handling recorded chunks
            mediaRecorder.ondataavailable = handleDataAvailable;

            //clear errors on stop record
            mediaRecorder.onstop = async function () {
              setError(null);
              setMediaRecorderStatus('stopped');
            };
          },
          function (err) {
            //this triggers an error for some odd reason
            setError(err.name);
          },
        )
        .catch((error) => {
          setError('ER02: Can not get user media');
        });
    } else {
      setError('ER03: WebRTC not supported');
    }
  }

  useEffect(() => {
    const w = Cookie.get('width');
    const h = Cookie.get('height');
    if (w && h) {
      console.log('found coocies', w, h);
      getAccess({ width: w, height: h });
    } else {
      Cookie.set('width', 1280);
      Cookie.set('height', 720);
      getAccess({ width: 1280, height: 720 });
    }
    //get access to camera as on load
    //  console.log("reload get access <------------");
  }, []);

  function handleDataAvailable(event) {
    console.log('!!!!!! handleDataAvailable', event);
    //push array of event chunks to local
    var recordedChunks = [];

    if (event.size > 0) {
      recordedChunks.push(event);
      console.log(
        recordedChunks,
        'recordedChunksrecordedChunksrecordedChunks !!!!!!',
      );
      saveToLocal(event);
    }
  }

  ///console.log(recordedChunks, '!!!!!! recordedChunks');
  useEffect(() => {
    //watch if mediaRecorderRef changes state
    if (mediaRecorderRef?.current?.[0]?.state) {
      setMediaRecorderStatus(mediaRecorderRef?.current?.[0]?.state);
    }
  }, [mediaRecorderRef?.current?.[0]?.state]);

  const stopRecord = () => {
    // make sure mediaRecorderRef is recording
    // if (mediaRecorderRef?.current?.[0]?.state === 'recording') {
    setMediaRecorderStatus('stopping');
    mediaRecorderRef.current[0].stopRecording();
    //}
  };
  const startRecord = () => {
    //make sure mediaRecorderRef is not aready recording
    if (
      mediaRecorderRef?.current?.[0] &&
      mediaRecorderRef?.current?.[0]?.state !== 'recording'
    ) {
      setStreamRef(Date.now());
      mediaRecorderRef.current[0].startRecording();
      setMediaRecorderStatus('recording');
    }
    checkExistingLocal();
  };

  return (
    <VideoCard
      selectedResolution={selectedResolution}
      setSelectedResolution={setSelectedResolution}
      startRecord={startRecord}
      stopRecord={stopRecord}
      status={mediaRecorderStatus}
      error={error}
      uploadProgress={uploadProgress}
      cameraPermission={cameraPermission}
      trackUploadComplete={trackUploadComplete}
    />
  );
};

export default PixelMediaRecorder;
