import React from "react";
import { Input } from "@builtbypixel/plasma";

const Text = (props) => {
  const { setActive, onChange, defaultValue, onBlur, value, ...rest } = props;
  return (
    <Input
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => {
        onBlur();
        setActive(false);
      }}
      onFocus={() => setActive(true)}
      value={value}
      required={false}
      isRequired={false}
      {...rest}
    />
  );
};

export default Text;
