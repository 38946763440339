import Login from '../containers/Auth/Login'
import ForgotPassword from '../containers/Auth/ForgotPassword'
import ResetPassword from '../containers/Auth/ResetPassword'

import sessions from '../containers/Sessions/routes';
import users from '../containers/Users/routes';
import viewSession from '../containers/ViewSession/routes';
import clients from '../containers/Clients/routes';
import support from '../containers/TechnicalSupport/routes';
import zoomPage from '../containers/ZoomPage/routes';

import presenterLogin from '../containers/PresenterLogin/routes';
const routes = [   {
    path: '/',
    title: 'Login',
    exact: true,
    auth: false,
    component: Login,
    permissions: ['Admin', 'All']
 },
 {
    path: '/forgot-password',
    title: 'Forgot Password',
    exact: true,
    auth: false,
    component: ForgotPassword,
    permissions: ['Admin', 'All']
 },
 {
    path: '/reset-password',
    title: 'Reset Password',
    exact: true,
    auth: false,
    component: ResetPassword,
    permissions: ['Admin', 'All']
 },...sessions,...users,...viewSession,...clients,...presenterLogin,...support,...zoomPage];

export default routes;
