import React from "react";
import { BiAtom, BiExit } from "react-icons/bi";
import { FiUsers, FiLayers, FiRepeat, FiSun } from "react-icons/fi";
import {
  Flex,
  Icon,
  IconButton,
  Tooltip,
  Stack,
  useColorMode,
  Modal,
  ModalButton,
  ModalHeader,
  ModalContent,
  Box,
} from "@builtbypixel/plasma";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import SiteSelector from "./SiteSelector";
import { logout } from "../../helpers";

import { globalAtom } from "../../state/global";

const version = "../../package.json";

const SideBar = () => {
  const [globalState] = useRecoilState(globalAtom);
  const { toggleColorMode, colorMode } = useColorMode();
  const history = useHistory();

  return (
    <Flex
      h='100vh'
      w='60px'
      direction='column'
      justify='stretch'
      align='center'
      pt='15px'
      pb='5px'
      px='8px'
      bg='global.elementBg'
      zIndex='9990'
      borderRight='1px solid'
      borderColor='global.borderColour'
      //borderRight='solid 1px #CBD5E0'
    >
      <Flex mb={20}>
        <Icon fontSize='26px' color='primary'>
          <BiAtom />
        </Icon>
      </Flex>
      <Stack spacing='10px' flex={1}>
        {/* <IconButton
          rounded='full'
          size='lg'
          variant='ghost'
          onClick={() => history.push("/administrators")}
        >
          <Tooltip title='Admin Users' placement='right'>
            <FiUsers fontSize={20} />
          </Tooltip>
        </IconButton> */}
      </Stack>
      <Stack spacing='10px'>
        <IconButton
          rounded='full'
          size='lg'
          variant='ghost'
          onClick={() => toggleColorMode()}
        >
          <Tooltip
            title={colorMode === "light" ? "Dark Mode" : "Light Mode"}
            placement='right'
            zIndex='9991'
          >
            <FiSun
              fontSize={20}
              color={colorMode === "light" ? "black" : "white"}
            />{" "}
          </Tooltip>
        </IconButton>
        <IconButton
          rounded='full'
          size='lg'
          variant='ghost'
          bg='global.elementBg'
          onClick={() => logout()}
        >
          <Tooltip title='Logout' placement='right'>
            <BiExit
              fontSize={20}
              color={colorMode === "light" ? "black" : "white"}
            />{" "}
          </Tooltip>
        </IconButton>
        <Box fontSize='12px' opacity={0.5} textAlign='center'>
          {version.version}
        </Box>
      </Stack>
    </Flex>
  );
};

export default SideBar;
