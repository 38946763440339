import React, { useState } from "react";
import {
  Flex,
  Card,
  Text,
  Box,
  Button,
  Icon,
  useColorMode,
  useToast,
  Image,
  Alert,
} from "@builtbypixel/plasma";
import LinkButton from "../../../components/LinkButton";
import { BiAtom } from "react-icons/bi";
import { Form, Field } from "../../../components/Form";
import { useFormContext } from "react-hook-form";
import { getSite } from "../../../helpers";
import { useHttp } from "../../../hooks";

const SubmitButton = ({ loading }) => {
  const { submit } = useFormContext();
  return (
    <Button
      size='lg'
      isFullWidth
      variantColor='primary'
      mb={3}
      type='submit'
      isLoading={loading}
      onClick={() => submit()}
      loadingText='Loading...'
    >
      Request Reset Link
    </Button>
  );
};

const ForgotPassword = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const Http = useHttp(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    Http.post("/auth/forgot-password", data)
      .then((res) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        toast({
          title: "Error",
          message: "There was an error with your request",
          status: "error",
          position: "top",
          variant: "plain",
        });
        setLoading(false);
      });
  };

  return (
    <Flex
      align='center'
      justify='center'
      h='100vh'
      w='100vw'
      direction='column'
    >
      <Card maxWidth={500} borderRadius='20px' py='30px'>
        <Flex px={10} align='center' justify='center' w='100%'>
          <Image
            src={getSite?.clientLogoUrl[colorMode]}
            objectFit='contain'
            size='170px'
          />
        </Flex>
        <Flex direction='column' align='center' justify='center' w='100%'>
          <Text as='h1' fontSize='26px' fontWeight='bold' color='primary'>
            Password Reset
          </Text>

          <Box py={10} px={10} w='100%'>
            <Form onSubmit={onSubmit}>
              <Field
                name='email'
                component='text'
                variant='filled'
                size='lg'
                placeholder='Email Address'
                type='email'
                autoFocus
                mb={3}
                auto={false}
                rules={{ required: true }}
              />

              <SubmitButton loading={loading} />
            </Form>
            <LinkButton to='/'>
              <Button
                variant='ghost'
                isFullWidth
                variantColor='primary'
                size='lg'
              >
                Return to Login
              </Button>
            </LinkButton>
          </Box>

          {success && (
            <Box p='20px'>
              <Alert status='success' variant='solid'>
                An email has been sent with instructions on how to reset your
                password.
              </Alert>
            </Box>
          )}
        </Flex>
      </Card>
      <Icon fontSize='32px' color='gray.300' mt={5}>
        <BiAtom />
      </Icon>
    </Flex>
  );
};
export default ForgotPassword;
