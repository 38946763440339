import React, { useState, useMemo } from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";
import { IconButton, Text, Button } from "@builtbypixel/plasma";
import { FiEdit, FiArrowLeftCircle, FiCalendar } from "react-icons/fi";
import setup from "./setup";

// Extra Menu appears next to the create button

const extraMenu = () => {
  return <div></div>;
};

const List = () => {
  const columns = [
    {
      Header: "Session Title",
      accessor: "title",

      Cell: ({ value, row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original.id}`}>
          <IconButton variant='ghost' variantColor='primary' rounded='md'>
            <Button bg='none'>
              <FiEdit
                style={{ marginRight: "0.5em" }}
                fontSize='1rem'
                align='center'
                justify='center'
              ></FiEdit>
              <Text>{value}</Text>
            </Button>
          </IconButton>
        </LinkButton>
      ),
    },
    {
      Header: "Job Number",
      accessor: "job_number",
      Cell: ({ value }) => <Text color='primary'>{value}</Text>,
    },
    {
      Header: "Zoom Meeting ID",
      accessor: "zoom_meeting_id",
      Cell: ({ value }) => <Text color='primary'>{value}</Text>,
    },
    {
      Header: () => "View",
      id: "view",
      Cell: ({ row }) => (
        <LinkButton to={`view-session/${row.original.id}`}>
          <IconButton variant='ghost' variantColor='primary' rounded='md'>
            <Button border='2px #cecece solid' bg='none'>
              <FiArrowLeftCircle
                style={{ marginRight: "0.5em" }}
                fontSize={"1.2em"}
                align='center'
                justify='center'
              ></FiArrowLeftCircle>
              <p>Go To Session</p>
            </Button>
          </IconButton>
        </LinkButton>
      ),
    },
  ];

  const filters = useMemo(
    () => [
      {
        name: "dateFilter",
        component: "date",
        placeholder: "date",
        label: "Date",
      },
      {
        name: "zoomAccountFilter",
        component: "select",
        label: "Zoom Account",
        options: [
          {
            value: "Bob",
            id: 1,
            name: "Bob",
          },
          {
            value: "Steve",
            id: 2,
            name: "Steve",
          },
          {
            value: "Sarah",
            id: 3,
            name: "Sarah",
          },
        ],
      },
    ],
    []
  );

  return (
    <ListWrapper
      setup={setup}
      columns={columns}
      // extraMenu={extraMenu}
      //  filters={filters}
    ></ListWrapper>
  );
};

export default List;
