import { debounce } from "lodash";
import Cookie from "js-cookie";

export const triggerClientMessage = debounce(
  (
    channel,
    evTitle,
    user,
    status,
    error,
    uploadProgress,
    cameraPermission,
    selectedResolution
  ) => {
    const w = Cookie.get("width");
    const h = Cookie.get("height");
    channel.trigger(`client-private-${evTitle}-${user?.id}`, {
      user: { name: user?.name, email: user?.email, id: user?.id },
      status: error ? error : status,
      uploadProgress: uploadProgress,
      cameraPermission: cameraPermission,
      resolution: { width: w, height: h },
    });
  },
  1500
);

export const triggerRecordMessage = debounce(
  (channel, evTitle, user, recordStatus) => {
    channel.trigger(`client-private-${evTitle}-${user?.id}`, {
      record: recordStatus,
    });
  },
  1000
);

export const triggerPingMessage = debounce((channel, evTitle, user) => {
  channel.trigger(`client-private-${evTitle}-${user?.id}`, {
    ping: true,
  });
}, 5000);

export const triggerResolutionChangeMessage = debounce(
  (channel, evTitle, user, selectedResolution) => {
    const w = Cookie.get("width");
    const h = Cookie.get("height");
    channel.trigger(`client-private-${evTitle}-${user?.id}`, {
      resolution: { width: w, height: h },
    });
  },
  5000
);
