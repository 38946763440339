import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Box,
  Text,
  Flex,
  Tag,
  Progress,
  Select,
} from '@builtbypixel/plasma';
import { useHttp } from '@builtbypixel/nucleus';
import DownloadModal from '../../components/DownloadModal';
import styled from 'styled-components';
import { BsArrowRepeat } from 'react-icons/bs';
import Pusher from 'pusher-js';
const Http = useHttp();
let channel;
Pusher.logToConsole = true;

let authorizer = (channel, options) => {
  return {
    authorize: (socketId, callback) => {
      Http.get('/pusher/auth', {
        params: { socket_id: socketId, channel_name: channel.name },
      })
        .then((res) => {
          callback(null, res.data);
        })
        .catch((err) => {
          callback(new Error(`Error calling auth endpoint: ${err}`), {
            auth: '',
          });
        });
    },
  };
};

const ProgressWrap = styled.div`
  width: 100%;
div > div {
    background-color: #1e90ff;
  }
  }
`;

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: 'eu',
  authorizer: authorizer,
});
const videoResolutions = [
  {
    width: 1280,
    height: 720,
  },
  {
    width: 320,
    height: 240,
  },
  {
    width: 640,
    height: 480,
  },
  {
    width: 1920,
    height: 1080,
  },
  {
    width: 2560,
    height: 1440,
  },
  {
    width: 3840,
    height: 2160,
  },
];
const HostView = ({
  hostSessionData,
  handleDownloadFromRemote,
  fetchRemoteVideos,
}) => {
  const [presenterVideoData, setPresenterVideoData] = useState([]);
  const [connectingToPusher, setConnectingToPusher] = useState('Connecting...');
  const [editResolution, setEditResolution] = useState(false);

  // ping function for checking presenter status
  const pingStatus = (id) => {
    const evTitle = hostSessionData.title.replace(/[^A-Z0-9]/gi, '_');
    channel.trigger(`client-private-${evTitle}-${id}`, {
      ping: true,
    });
  };

  // record toggle function for presenter
  const sendToPresenter = (id, recordStatus) => {
    const evTitle = hostSessionData.title.replace(/[^A-Z0-9]/gi, '_');
    channel.trigger(`client-private-${evTitle}-${id}`, {
      record: recordStatus,
    });
  };

  // change resolution for presenter
  const setSelectedResolution = (id, resolution) => {
    const evTitle = hostSessionData.title.replace(/[^A-Z0-9]/gi, '_');
    channel.trigger(`client-private-${evTitle}-${id}`, {
      resolution: resolution,
    });
  };

  useEffect(() => {
    if (hostSessionData?.title) {
      //subscibe to event
      channel = pusher.subscribe(
        `private-${hostSessionData.title.replace(/[^A-Z0-9]/gi, '_')}`,
      );

      channel.bind('pusher:subscription_succeeded', function () {
        setConnectingToPusher(false);

        //subscribe to each presenters individual channel
        if (hostSessionData?.presenters?.length > 0) {
          hostSessionData.presenters.map((presenter) => {
            channel.bind(
              `client-private-${hostSessionData.title.replace(
                /[^A-Z0-9]/gi,
                '_',
              )}-${presenter.id}`,
              function (data) {
                setPresenterVideoData((old) => ({
                  ...old,
                  [presenter.id]: data,
                }));
                if (data?.uploadProgress === 100) {
                  console.log('!!!! UPLOAD COMPLERE FETCH FETCH FETCH');
                  fetchRemoteVideos();
                }
              },
            );
          });
        }
        //check for errors
        channel.bind('pusher:subscription_error', (error) => {
          setConnectingToPusher('Unable to connect to presenter');
        });

        //ping each presenter to check their status
        const res = hostSessionData?.presenters?.map((presenter, i) =>
          pingStatus(presenter.id),
        );
      });
    }
  }, [hostSessionData]);

  return (
    <Grid height="100%" templateColumns="repeat(3,1fr)" gap="10px" m="1em">
      {hostSessionData?.presenters?.map((presenter, i) => {
        return (
          <Flex
            direction="column"
            w="100%"
            rowGap={1}
            columnGap={1}
            borderRadius="6px"
            fontSize="0.9rem"
            fontWeight="600"
            color="primary"
          >
            {presenter?.videos?.length > 0 && (
              <Box position="relative">
                <video
                  src={presenter.videos[presenter.videos.length - 1].url}
                  className="video"
                  muted={true}
                  type="video/webm"
                  controls="true"
                />
                <Text color="white" position="absolute" top="0" left="5px">
                  Latest Video
                </Text>
              </Box>
            )}
            <Box
              bg="global.elementBg"
              mb="1em"
              border="1px solid"
              borderColor="#EEEEEE"
              borderRadius="0 0 0.5em 0.5em"
            >
              <Flex
                justifyContent="space-between"
                py="0.5em"
                borderBottom="1px solid #EEEEEE"
              >
                <Text pl="1em">Presenter</Text>
                <Text pr="1em"> {presenter?.name}</Text>
              </Flex>
              {connectingToPusher ? (
                connectingToPusher
              ) : (
                <Flex direction="column">
                  <Flex
                    justifyContent="space-between"
                    py="0.5em"
                    borderBottom="1px solid #EEEEEE"
                    pr="1em"
                  >
                    <Text pl="1em">Status</Text>
                    <Tag
                      variant="solid"
                      bg={presenterVideoData[presenter.id] ? 'green' : 'red'}
                    >
                      {presenterVideoData[presenter.id]?.uploadProgress > 0 &&
                      presenterVideoData[presenter.id]?.uploadProgress < 100
                        ? 'Uploading'
                        : presenterVideoData[presenter.id]
                        ? 'Online'
                        : 'Offline'}
                    </Tag>
                  </Flex>

                  <ProgressWrap>
                    <Progress
                      w="100%"
                      bgcolor="blue"
                      style={{ backgroundColor: 'blue' }}
                      value={presenterVideoData[presenter.id]?.uploadProgress}
                    />
                  </ProgressWrap>
                </Flex>
              )}
              <Flex
                justifyContent="space-between"
                py="0.5em"
                borderBottom="1px solid #EEEEEE"
              >
                <Text pl="1em">Camera</Text>{' '}
                <Text pr="1em">
                  {' '}
                  {presenterVideoData[presenter.id]?.status}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                py="0.5em"
                borderBottom="1px solid #EEEEEE"
              >
                <Text pl="1em">Permission</Text>{' '}
                <Text pr="1em">
                  {' '}
                  {presenterVideoData[presenter.id]?.cameraPermission}
                </Text>
              </Flex>

              <Flex
                justifyContent="space-between"
                align="center"
                py="0.5em"
                borderBottom="1px solid #EEEEEE"
              >
                <Text pl="1em">Resolution</Text>{' '}
                {editResolution ? (
                  <Flex px="5px">
                    <Select
                      options={videoResolutions}
                      labelKey="name"
                      valueKey="width"
                      value={presenterVideoData[presenter.id]?.resolution}
                      onChange={(e) => {
                        setSelectedResolution(presenter.id, e);
                        setEditResolution(false);
                      }}
                      formatOptionLabel={(option) =>
                        option.width + 'x' + option.height
                      }
                      placeholder="Change resolution"
                    />
                  </Flex>
                ) : (
                  <Flex
                    pr="1em"
                    align="center"
                    cursor="pointer"
                    onClick={() => setEditResolution(true)}
                  >
                    {presenterVideoData[presenter.id]?.resolution && (
                      <Text pr="5px">
                        {presenterVideoData[presenter.id]?.resolution?.width} x{' '}
                        {presenterVideoData[presenter.id]?.resolution?.height}
                      </Text>
                    )}
                    <BsArrowRepeat />
                  </Flex>
                )}
              </Flex>

              <Flex w="100%" p="1em" justify="center" align="center">
                {presenterVideoData[presenter.id]?.status === 'recording' ? (
                  <Button
                    w="100%"
                    onClick={() => {
                      sendToPresenter(presenter.id, false);
                    }}
                  >
                    Stop Recording
                  </Button>
                ) : (
                  <Button
                    w="100%"
                    onClick={() => {
                      sendToPresenter(presenter.id, true);
                    }}
                  >
                    Start Recording
                  </Button>
                )}
              </Flex>
              {presenter?.videos?.length > 0 && (
                <Box p="1em">
                  <DownloadModal
                    isHost={true}
                    remoteRecordings={transformArr(presenter)}
                    handleDownloadFromRemote={handleDownloadFromRemote}
                  />
                </Box>
              )}
            </Box>
          </Flex>
        );
      })}
    </Grid>
  );
};

export default HostView;

const transformArr = (presenter) => {
  const orig = presenter.videos;

  var newArr = [],
    types = {},
    i,
    j,
    cur;
  for (i = 0, j = orig?.length; i < j; i++) {
    cur = orig[i];
    if (!(cur.stream_ref in types)) {
      types[cur.stream_ref] = { type: cur.stream_ref, url: [] };
      newArr.push(types[cur.stream_ref]);
    }
    types[cur.stream_ref].url.push(cur.url);
  }
  return newArr;
};
