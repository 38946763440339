function createCrud({ setup, name, edit, list, view = null }) {
  const url = name.replace(/\s+/g, '-').toLowerCase()
  const array = [
    setup.canAddNew && {
      path: `/:model(${url})/:type(create)`,
      title: `${name} - Create`,
      exact: true,
      auth: true,
      component: edit,
      permissions:['Admin','RDA']
    },
    {
      path: `/:model(${url})/:type(edit)/:id`,
      title: `${name} - Edit`,
      exact: true,
      auth: true,
      component: edit,
      permissions:['Admin','RDA']
    },
    {
      path: `/:model(${url})`,
      title: `${name} - View All`,
      exact: true,
      auth: true,
      component: list,
      permissions:['Admin','RDA']
    }
  ]

  if (view !== null) {
    array.splice(1, 0, {
      path: `/:model(${url})/:type(view)/:id`,
      title: `${name} - View`,
      exact: true,
      auth: true,
      component: view,
      permissions:['Admin','RDA']
    })
  }

  return array
}

export default createCrud
