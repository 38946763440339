import { createCrudRoutes } from "../../hooks";
import List from "./List";
import Entry from "./";
import View from "./View";

import setup from "./setup";

const crud = createCrudRoutes({
  setup,
  name: setup.title,
  edit: Entry,
  list: List,
  view: View,
  permissions: ['Admin', 'All']
});

const otherRoutes = [
  {
    path: "/sessions",
    title: "View Sessions",
    exact: true,
    auth: true,
    component: List,
    permissions: ['Admin', 'All']
  },
  {
    path: "/sessions/:job_number",
    title: "View Session",
    exact: false,
    auth: true,
    component: View,
    permissions: ['Admin', 'All']
  },
];

const routes = [...crud, ...otherRoutes];

export default routes;
